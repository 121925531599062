<template>
    <validation-provider
        v-slot="{ errors }"
        :name="name"
        :rules="rules"
        :vid="vid"
    >
        <label class="col-form-label" :for="vid">{{ required ? name + ' * ' : name }}</label>
        <b-input-group class="mb-1">
            <b-form-input
                :id="vid"
                invalid-feedback="errors[0]"
                :placeholder="placeholder"
                :state="errors.length > 0 ? false : null"
                :type="type"
                :value="value"
                :dir="dir"
                :formatter="formatter"
                :debounce="debounce"
                :readonly="readonly"
                :plaintext="plaintext"
                :lazy-formatter="lazyFormatter"
                @input="emitInput"
            />
            <b-form-invalid-feedback :id="vid">{{ errors[0] }}</b-form-invalid-feedback>
            <b-form-text v-if="description" :id="vid">{{ description }}</b-form-text>

            <template v-for="(_, slot) in $slots">
                <template :slot="slot">
                    <slot :name="slot" />
                </template>
            </template>
        </b-input-group>
    </validation-provider>

</template>

<script>
import { BFormInput, BInputGroup } from "bootstrap-vue";
import { DefaultInputProps } from "@/layouts/components/form/defaultProps";

export default {
    name: "CustomInputGroup",

    components: {
        BInputGroup,
        BFormInput
    },

    props: {
        ...DefaultInputProps
    },

    methods: {
        emitInput(value) {
            this.$emit('input', value)
        }
    }

}
</script>
