<template>
    <validation-observer ref="form" v-slot="{ invalid }" tag="div">
        <b-overlay :show="status === 'LOADING' && Boolean($route.params.id)">
            <b-form @submit.prevent="submit">
                <b-tabs>
                    <b-tab :title="$t('general')">
                        <b-row>
                            <b-col cols="12">
                                <custom-input
                                    v-model="post.title"
                                    :name="$t('title')"
                                    required
                                    rules="required|min:2|max:255"
                                    vid="title"
                                />
                            </b-col>
                            <b-col cols="12">
                                <custom-input
                                    v-model="post.slug"
                                    :name="$t('slug')"
                                    rules="max:255"
                                    vid="slug"
                                />
                            </b-col>
                            <b-col class="status-mt" lg="2" md="6">
                                <custom-select
                                    v-model="post.status"
                                    :name="$t('status')"
                                    :options="postStatusOptions"
                                    required
                                    vid="status"
                                />
                            </b-col>
                            <b-col lg="4" md="6">
                                <label class="col-form-label">{{ $t('publish date') }}</label>
                                <date-picker
                                    v-model="post.published_at"
                                    display-format="dddd jDD jMMMM jYYYY HH:mm"
                                    format="YYYY-MM-DD HH:mm:ss"
                                    type="dateTime"
                                />
                            </b-col>
                            <b-col md="6">
                                <custom-input-group
                                    v-model="post.imdb"
                                    :name="$t('imdb')"
                                    dir="ltr"
                                    required
                                    rules="required|max:35"
                                    vid="imdb"
                                >
                                    <template #prepend>
                                        <b-button variant="secondary" @click="findImdb">
                                            {{ $t('search') }}
                                        </b-button>
                                    </template>
                                </custom-input-group>
                                <div v-if="imdbLoading" class="text-danger my-2">
                                    <span>لطفا صبر کنید. </span>
                                    <b-spinner type="grow"/>
                                </div>
                                <div v-if="poster" class="my-2 d-flex">
                                    <a :href="poster" target="_blank">پوستر</a>
                                </div>
                                <b-alert :show="imdbError" class="p-2" dismissible variant="warning">
                                    متاسفانه خطایی رخ داده است. لطفا بعدا امتحان کنید.
                                </b-alert>
                            </b-col>
                            <b-col class="mb-2" md="6">
                                <label class="col-form-label">{{ $t('actors') }}</label>
                                <v-select
                                    v-model="post.actors"
                                    :options="selectableActors"
                                    dir="ltr"
                                    multiple
                                    @search="searchActors"
                                >
                                    <template #no-options>
                                        {{ $t('no match') }}
                                    </template>
                                </v-select>
                            </b-col>
                            <b-col class="mb-2" md="6">
                                <label class="col-form-label">{{ $t('genre') }}</label>
                                <v-select
                                    v-model="post.genres"
                                    :options="selectableGenres"
                                    dir="auto"
                                    multiple
                                >
                                    <template #no-options>
                                        {{ $t('no match') }}
                                    </template>
                                </v-select>
                            </b-col>
                            <b-col class="mb-2" md="6">
                                <label class="col-form-label">{{ $t('category._') }}</label>
                                <v-select
                                    v-model="post.categories"
                                    :options="selectableCategories"
                                    dir="auto"
                                    multiple
                                >
                                    <template #no-options>
                                        {{ $t('no match') }}
                                    </template>
                                </v-select>
                            </b-col>
                            <b-col md="6">
                                <custom-fm-button v-model="post.poster" :name="$t('poster')" vid="poster"/>
                            </b-col>
                            <b-col class="mb-2" cols="12">
                                <label class="col-form-label">{{ $t('compatible') }}</label>
                                <v-select
                                    v-model="post.compatible"
                                    :options="QualityListOptions"
                                    multiple
                                />
                            </b-col>
                            <b-col cols="12">
                                <custom-input
                                    v-model="post.translators"
                                    :name="$t('translators')"
                                    rules="max:255"
                                    vid="translators"
                                />
                            </b-col>
                            <b-col cols="12">
                                <ckeditor-custom v-model="post.summary" :content="post.summary"/>
                            </b-col>

                        </b-row>
                    </b-tab>
                    <b-tab :title="$t('parts')">
                        <!-- Row Loop -->
                        <b-row
                            v-for="(item, index) in post.body"
                            :id="item.id"
                            :key="item.id"
                            ref="row"
                        >
                            <!-- Item Name -->
                            <b-col md="2">
                                <custom-input
                                    v-model="item.title"
                                    :name="$t('title')"
                                    :value="item.title"
                                    vid="title_option"
                                />
                            </b-col>
                            <b-col md="8">
                                <custom-input
                                    v-model="item.url"
                                    :name="$t('url')"
                                    :value="item.url"
                                    dir="ltr"
                                    vid="url"
                                />
                            </b-col>

                            <!-- Remove Button -->
                            <b-col align-self="center" class="mb-50" lg="2" md="3">
                                <b-button class="mt-0 mt-md-2" variant="outline-danger" @click="removeItem(index)">
                                    <feather-icon icon="XIcon"/>
                                    <span>{{ $t('Delete') }}</span>
                                </b-button>
                            </b-col>
                            <b-col cols="12">
                                <hr>
                            </b-col>
                        </b-row>
                        <b-button variant="primary" @click="repeatAgain">
                            <feather-icon class="mr-25" icon="PlusIcon"/>
                            <span>{{ $t('Add') }}</span>
                        </b-button>
                    </b-tab>
                    <b-tab :title="$t('SEO')">
                        <b-row>
                            <b-col cols="12">
                                <custom-input
                                    v-model="post.meta_title"
                                    :name="$t('meta_title')"
                                    rules="max:255"
                                    vid="meta_title"
                                />
                            </b-col>
                            <b-col cols="12">
                                <custom-tags
                                    v-model="post.meta_keywords"
                                    :name="$t('meta_keywords')"
                                    rules="max:255"
                                    vid="meta_keywords"
                                />
                            </b-col>
                            <b-col cols="12">
                                <custom-text-area
                                    v-model="post.meta_description"
                                    :name="$t('meta_description')"
                                    rules="max:255"
                                    vid="meta_description"
                                />
                                <small v-if="post.meta_description">
                                    {{ $t('characters_count',{count: post.meta_description.length} ) }}
                                </small>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-tabs>

                <b-alert :show="errMessage !== null" class="p-2" dismissible variant="danger">
                    {{ errMessage }}
                </b-alert>

                <b-button
                    v-if="$can('post-edit') || $can('post-create')"
                    :disabled="invalid"
                    class="mt-2"
                    type="submit"
                    variant="primary"
                >
                    {{ $t('save') }}
                </b-button>

            </b-form>
        </b-overlay>
    </validation-observer>
</template>

<script>
import { BAlert, BTab, BTabs } from "bootstrap-vue";
import CustomInputGroup from "@/layouts/components/form/CustomInputGroup";
import CustomTags from "@/layouts/components/form/CustomTags";
import { makeSelectable } from "@/common/arrays";
import '@/libs/vue-select'
import { mapActions, mapGetters } from "vuex";
import { postStatusOptions, QualityListOptions } from "@/common/data";
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import PostStatus from "@/constans/PostStatus";
import CustomFmButton from "@/layouts/components/form/CustomFmButton";
import ckeditorCustom from "@/layouts/components/form/CKeditor";

export default {
    name: "Form",

    components: {
        CustomTags,
        BAlert,
        BTabs,
        BTab,
        CustomInputGroup,
        CustomFmButton,
        ckeditorCustom,
        datePicker: VuePersianDatetimePicker,
    },

    props: {
        submit: {
            type: Function,
            required: true,
        },
        post: {
            default() {
                return {
                    title: '',
                    slug: '',
                    imdb: '',
                    compatible: '',
                    translators: '',
                    published_at: '',
                    summary: '',
                    poster: '',
                    body: [],
                    status: PostStatus.DRAFT,
                    meta_title: '',
                    meta_keywords: [],
                    meta_description: '',
                    actors: [],
                    genres: [],
                    categories: [],
                }
            },
            type: Object
        }
    },

    data() {
        return {
            errMessage: null,
            loading: false,
            imdbLoading: false,
            imdbError: false,
            poster: null,
            QualityListOptions,
            PostStatus,
            nextTodoId: 1,
            postStatusOptions
        }
    },

    computed: {
        ...mapGetters('post', [
            'status'
        ]),
        ...mapGetters('genre', [
            'selectableGenres'
        ]),
        ...mapGetters('actor', [
            'selectableActors'
        ]),
        ...mapGetters('category', [
            'selectableCategories'
        ])
    },

    mounted() {
        this.fetchGenres({ per_page: 35 })
        this.fetchCategories()
        window.fmSetLink = this.fmSetLink
    },

    methods: {
        findImdb() {
            this.imdbLoading = true
            this.imdbError = false

            this.$http.get(process.env.VUE_APP_BASE_API_URL + 'imdb/' + this.post.imdb).then(res => {
                const data = res.data

                this.post.actors = makeSelectable(data.actors, 'id', 'name')
                this.post.genres = makeSelectable(data.genres, 'id', 'title')
                this.post.title = data.title
                this.poster = data.poster
                this.imdbLoading = false
            }).catch(error => {
                this.imdbLoading = false
                this.imdbError = true
            })
        },
        ...mapActions({
            fetchGenres: 'genre/fetch',
            fetchCategories: 'category/fetch',
            fetchActors: 'actor/fetch',
        }),
        searchActors(search = '', loading) {
            loading(true)
            this.fetchActors({
                name: search
            }).then(() => {
                loading(false)
            })
        },
        repeatAgain() {
            this.post.body.push({
                id: this.nextTodoId++,
            })
        },
        removeItem(index) {
            this.post.body.splice(index, 1)
        },
        fmSetLink($url) {
            this.post.poster = $url;
        }

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.status-mt {
    margin-top: 0.5rem;
}
</style>